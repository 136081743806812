<div class="quest-container">
  <!-- Work -->
  <div class="category">
    <h2>Story Quests</h2>
  </div>
  <span class="spacer"></span>
  <br />
  <div class="log">
    <div class="title">
      <h3>
        <a href="https://www.mortgage365.com/" target="_blank"
          >Mortgage365 <i class="fas fa-external-link"></i
        ></a>
      </h3>
      <h4>8/2017 - Current</h4>
    </div>
    <br />
    <h4>Angular, ASP.Net</h4>
    <h4>JavaScript, TypeScript, C#</h4>
    <br />
    <ul class="points">
      <li>- Build Open House Flyer Engine <i class="fas fa-check"></i></li>
      <li>
        - Rebuild Core Angular.js Application in Angular 4+
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Learn C# and ASP.Net, Move Into Full Stack Role
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Write Entire AUS Engine For Fannie Mae and Freddie Mac
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Split Angular Application Into Lazy Loaded Modules
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Set Up Angular Service Worker For Increased Performance
        <i class="fas fa-check"></i>
      </li>
    </ul>
  </div>
  <br />
  <div class="log">
    <div class="title">
      <h3>
        <a href="https://www.blinker.com/" target="_blank"
          >Blinker <i class="fas fa-external-link"></i
        ></a>
      </h3>
      <h4>5/2020 - 10/2020</h4>
    </div>
    <br />
    <h4>React, Pheonix</h4>
    <h4>JavaScript, TypeScript, Elixir</h4>
    <br />
    <ul class="points">
      <li>
        - Learn React, Elixir and Phoenix
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Contribute to Autotrader Peer to Peer Car Sales Flow
        <i class="fas fa-check"></i>
      </li>
    </ul>
  </div>
  <br />
  <div class="log">
    <div class="title">
      <h3>
        <a href="https://www.brytercx.com/" target="_blank"
          >BryterCX <i class="fas fa-external-link"></i
        ></a>
      </h3>
      <h4>2/2020 - 5/2020</h4>
    </div>
    <br />
    <h4>Angular, Spring</h4>
    <h4>JavaScript, TypeScript, Java</h4>
    <br />
    <ul class="points">
      <li>
        - Learn Angular Modulization and Build Optomization
        <i class="fas fa-check"></i>
      </li>
      <li>
        - Move Auth Provider From Gluu to Okta <i class="fas fa-check"></i>
      </li>
    </ul>
  </div>
  <!-- Side Projects/LLC Work -->
  <!-- <br />
  <div class="category">
    <h2>Side Quests</h2>
  </div>
  <span class="spacer"></span>
  <br /> -->
  <!-- School -->
  <br />
  <div class="category">
    <h2>Tutorial</h2>
  </div>
  <span class="spacer"></span>
  <br />
  <div class="log">
    <div class="title">
      <h3>
        <a href="https://www.galvanize.com/" target="_blank"
          >Galvanize <i class="fas fa-external-link"></i
        ></a>
      </h3>
      <h4>2016-2017</h4>
    </div>
    <br />
    <h4>Angular, Express.js, Handlebars.js, Ruby on Rails</h4>
    <h4>JavaScript, TypeScript, HTML, CSS, SASS/SCSS</h4>
    <br />
    <ul class="points">
      <li>- Learn Basic Web Technology <i class="fas fa-check"></i></li>
      <li>
        - Learn APIs and Server Side Rendering <i class="fas fa-check"></i>
      </li>
      <li>- Learn SPA JavaScript Frameworks <i class="fas fa-check"></i></li>
      <li>- Build Final Project <i class="fas fa-check"></i></li>
      <li>- Graduate <i class="fas fa-check"></i></li>
    </ul>
  </div>
</div>
