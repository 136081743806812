<article class="content">
  <div class="tabs">
    <div class="tab" [class.active]="openTab === 0" (click)="openTab = 0">
      <p>Stats</p>
    </div>
    <div class="tab" [class.active]="openTab === 1" (click)="openTab = 1">
      <p>Quest Log</p>
    </div>
  </div>
  <div class="tab-content">
    <aside *ngIf="openTab === 0">
      <app-stats></app-stats>
    </aside>
    <section *ngIf="openTab === 1">
      <app-quest-log></app-quest-log>
    </section>
  </div>
</article>
