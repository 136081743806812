<nav>
  <div class="character">
    <div class="portrait">
      <img src="/assets/images/ethan.png" alt="Ethan Image" />
    </div>
    <h1>Ethan Granato</h1>
  </div>
</nav>
<main>
  <router-outlet></router-outlet>
</main>
