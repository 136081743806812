<div class="stat-container">
  <!-- Frameworks -->
  <div class="category">
    <h2>Frameworks</h2>
  </div>
  <span class="spacer"></span>
  <br />
  <div class="skill">
    <h3>Angular</h3>
    <div class="rating">
      <h4>10</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Express.js</h3>
    <div class="rating">
      <h4>8</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>ASP.Net</h3>
    <div class="rating">
      <h4>7</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>.Net Core</h3>
    <div class="rating">
      <h4>5</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>React</h3>
    <div class="rating">
      <h4>5</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Handlebars.js</h3>
    <div class="rating">
      <h4>5</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Spring</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Elixir</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Ruby on Rails</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
  <!-- Languages -->
  <br />
  <div class="category">
    <h2>Languages</h2>
  </div>
  <span class="spacer"></span>
  <br />
  <div class="skill">
    <h3>JavaScript</h3>
    <div class="rating">
      <h4>10</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>HTML</h3>
    <div class="rating">
      <h4>10</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>CSS (SASS/SCSS)</h3>
    <div class="rating">
      <h4>10</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>TypeScript</h3>
    <div class="rating">
      <h4>9</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>C#</h3>
    <div class="rating">
      <h4>7</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>SQL</h3>
    <div class="rating">
      <h4>6</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Java</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Pheonix</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
  <div class="skill">
    <h3>Ruby</h3>
    <div class="rating">
      <h4>3</h4>
      <h5>/10</h5>
    </div>
  </div>
</div>
